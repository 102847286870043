import React from "react";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import Info from "../components/vulnerabilities-disclosure-policy/Info";
import "../components/vulnerabilities-disclosure-policy/index.scss";

const VulnerabilitiesDisclosurePolicy = () => {
  return (
    <Layout customClass="PgVDP">
      <Seo
        title="Vulnerabilities Disclosure Policy | AMZScout"
        description=""
        page="vulnerabilities-disclosure-policy"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <Info />
    </Layout>
  );
};

export default VulnerabilitiesDisclosurePolicy;
