import React from "react";
import "./index.scss";

const GeneralRules = () => {
  return (
    <div className="PgVDP-I-section">
      <h2 className="PgVDP-I-section__title">General Rules</h2>
      <p>
        Any activities that comply with this Policy shall be acknowledged as
        properly authorized by AMZScout, and we will not initiate legal action
        against you as long as you act in accordance with the terms and
        conditions of this Policy. AMZSCOUT RESERVES ALL LEGAL RIGHTS IN THE
        EVENT OF NONCOMPLIANCE WITH THIS POLICY.
      </p>
      <p>
        For the purposes of testing, you are eligible to use your own account
        and credentials only. Any actions intended to use/hack accounts of other
        users are prohibited.
      </p>
      <p>Any automated testing is prohibited.</p>
      <p>
        Brute-force attacks, DDoS/DoS attacks are not consistent with this
        Policy and are completely prohibited.
      </p>
      <p>
        It is prohibited to violate the integrity, availability and
        confidentiality conditions for AMZScout’s website/the Extensions.
      </p>
      <p>
        Vulnerability testing shall be performed using a minimally adequate set
        of checks.
      </p>
      <p>You shall comply with the Disclosure Conditions described below.</p>
      <p>
        To the extent not regulated by the terms and conditions of this Policy,
        the general Terms of Use of the relevant AMZScout’s service shall be
        applied.
      </p>
    </div>
  );
};

export default GeneralRules;
