import React from "react";
import Introduction from "./Introduction";
import Scope from "./Scope";
import RestrictionsAndExclusions from "./RestrictionsAndExclusions";
import GeneralRules from "./GeneralRules";
import SubmissionProcess from "./SubmissionProcess";
import DisclosureConditions from "./DisclosureConditions";
import Rewards from "./Rewards";
import "./index.scss";

const Info = () => {
  return (
    <section className="PgVDP-I">
      <div className="PgVDP__container">
        <h1 className="PgVDP-I__title">Vulnerabilities Disclosure Policy</h1>
        <p className="PgVDP-I__subTitle">
          Effective date (last update): 2023/02/01
        </p>
        <Introduction />
        <Scope />
        <RestrictionsAndExclusions />
        <GeneralRules />
        <SubmissionProcess />
        <DisclosureConditions />
        <Rewards />
      </div>
    </section>
  );
};

export default Info;
