import React from "react";
import "./index.scss";

const Rewards = () => {
  return (
    <div className="PgVDP-I-section">
      <h2 className="PgVDP-I-section__title">Rewards</h2>
      <p>
        AMZScout, in its sole reasonable discretion, may reward you with a
        subscription to one of AMZScout’s services/tools, or with a digital
        analytical product by AMZScout if you are the first person to report a
        given vulnerability, and this vulnerability is within the scope
        described above, provided you follow the guidelines of this Policy.
        AMZScout’s decision whether to reward for vulnerability reporting is in
        its sole discretion, final, and is not subject to appeal.
      </p>
    </div>
  );
};

export default Rewards;
