import React from "react";
import "./index.scss";

const RestrictionsAndExclusions = () => {
  return (
    <div className="PgVDP-I-section">
      <h2 className="PgVDP-I-section__title">Restrictions and Exclusions</h2>
      <p>
        This Policy covers only technical vulnerabilities that may affect
        sensitive data, critical services, or operability of the
        website/services.
      </p>
      <p>
        The following vulnerabilities and testing of them are outside of the
        scope of this Policy:
      </p>
      <ol>
        <li>Social engineering/phishing</li>
        <li>DDoS attacks</li>
        <li>Brute-force attacks and any automated tests</li>
        <li>Clickjacking and issues only exploitable through clickjacking</li>
        <li>Weak Captcha/Captcha Bypass</li>
        <li>
          Any issues connected with third-party libraries, tools, and
          third-party websites integrated with the AMZScout’s website/Extensions
        </li>
        <li>Self-XSS without the ability to attack other users</li>
        <li>
          Lack of recommended security mechanisms or unsafe configured TLS or
          SSL without an additional attack vector
        </li>
        <li>
          Vulnerabilities, a necessary condition for the operation of which is
          the presence of malicious software and/or root rights
        </li>
        <li>Improper UX/UI design and spelling mistakes</li>
        <li>
          Disclosure of technical or insensitive information, IP addresses, DNS
          records, open ports and similar details
        </li>
        <li>
          Vulnerabilities that require the implementation of complex or
          improbable scenarios of user interaction
        </li>
        <li>
          Hypothetical questions that have no practical value, or are unlikely
          to be a safety hazard for the website/the Extensions
        </li>
        <li>
          Any issues that, at the AMZScout’s sole reasonable discretion, do not
          affect critical functions of the website/the Extensions or sensitive
          data, or have no practical impacts or plausible attack vector
        </li>
      </ol>
    </div>
  );
};

export default RestrictionsAndExclusions;
