import React from "react";
import TrackedLink from "../../Analytics/TrackedLink";
import "./index.scss";

const Scope = () => {
  return (
    <div className="PgVDP-I-section PgVDP-I-Scope">
      <h2 className="PgVDP-I-section__title">Scope</h2>
      <p>The scope of vulnerabilities covered by this Policy:</p>
      <ol>
        <li>
          <TrackedLink
            path="/"
            // rel="nofollow"
            category="VulnerabilitiesDisclosurePolicy"
            class="PgVDP-I__link"
            action="https://amzscout.net"
          >
            https://amzscout.net
          </TrackedLink>{" "}
          and any of its subdomains and pages (the website);
        </li>
        <li>All AMZScout’s Extensions for browsers (the Extensions).</li>
      </ol>
      <p>
        Any reports or requests that are out of this scope will not be
        considered.
      </p>
    </div>
  );
};

export default Scope;
