import React from "react";
import TrackedLink from "../../Analytics/TrackedLink";
import "./index.scss";

const SubmissionProcess = () => {
  return (
    <div className="PgVDP-I-section">
      <h2 className="PgVDP-I-section__title">Submission Process</h2>
      <p>
        If you believe you have discovered a vulnerability, you shall submit the
        relevant report to our support service via email:{" "}
        <TrackedLink
          path="mailto:support@amzscout.net"
          // rel="nofollow"
          category="VulnerabilitiesDisclosurePolicy"
          class="PgVDP-I__link"
          action="support@amzscout.net"
        >
          support@amzscout.net
        </TrackedLink>
        . We will notify you about the stages of evaluation of your report,
        including but not limited to registration, validation with respect to
        the scope of this Policy, detailed evaluation of the vulnerability, a
        bounty decision if any.
      </p>
    </div>
  );
};

export default SubmissionProcess;
