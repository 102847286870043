import React from "react";
import "./index.scss";

const Introduction = () => {
  return (
    <div className="PgVDP-I-section">
      <h2 className="PgVDP-I-section__title">Introduction</h2>
      <p>
        This Vulnerabilities Disclosure Policy (the Policy) is focused on
        enhancing the security of AMZScout’s website and browser’s extensions
        and add-ons (the Extensions).
      </p>
      <p>
        All references to “we”, “our”, “us”, “AMZScout” herein shall be
        construed as AMZScout Corp. incorporated in Pennsylvania, United States.
      </p>
      <p>
        Recognition and bounties under this Policy are only possible subject to
        the terms and conditions described herein. We reserve the right not to
        respond to research requests, to request additional information, as well
        as to change the rules and conditions of this Policy.
      </p>
    </div>
  );
};

export default Introduction;
