import React from "react";
import "./index.scss";

const DisclosureConditions = () => {
  return (
    <div className="PgVDP-I-section">
      <h2 className="PgVDP-I-section__title">Disclosure Conditions</h2>
      <p>
        The vulnerability details may be published/discovered by AMZScout when
        the report of this vulnerability is fully evaluated, all checks are
        performed, necessary patches (improvements, fixing) are tested, applied
        and deployed.
      </p>
      <p>
        You are not allowed to disclose, in whole or partially, any
        vulnerabilities or discuss this information before it is disclosed by
        AMZScout, and the vulnerability is fixed.
      </p>
      <p>
        If any sensitive information including (but not limited to) personal
        identifiable information, any user’s and employee’s data, internal
        documentation, source code and interfaces is accidentally obtained
        during vulnerability research or demonstration this information must not
        be disclosed. Intentional access to this information is strictly
        prohibited.
      </p>
    </div>
  );
};

export default DisclosureConditions;
